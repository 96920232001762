import styled from '@emotion/styled';

export const DefaultLayout = styled.div<{ active: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ active }) => (active ? 'flex' : 'none')};
  flex-direction: column;
  padding-top: 44px;
  position: relative;

  @media screen and (max-width: 600px) {
    padding-top: 32px;
  }
`;
