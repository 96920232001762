import styled from '@emotion/styled';
import { MEDIA_TYPE, Story } from 'api/types';
import { DateTime } from 'luxon';
import { useRef } from 'react';
import { BodyScrollLock } from './BodyScrollLock';
const Box = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: black;
  box-shadow: rgb(0 0 0 / 50%) 0 0 10px;
`;
const Info = styled.div`
  font-size: 12px;
  position: absolute;
  color: white;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
`;
const StoryImage = styled.img`
  height: 95vh;
  object-fit: contain;
  @media (max-width: 750px) {
    height: 75vh;
  }
`;
const StoryVideo = styled.video`
  height: 95vh;
  object-fit: contain;
  @media (max-width: 750px) {
    height: 75vh;
  }
`;

const DEV_FEATURE = process.env.NODE_ENV === 'development';
export const InstagramStoryItem = ({ item }: { item: Story }): JSX.Element => {
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <BodyScrollLock allowedElement={divRef} />
      <Box ref={divRef}>
        {item.mediaType === MEDIA_TYPE.Video ? (
          <StoryVideo src={item.resource} controls playsInline />
        ) : (
          <StoryImage src={item.resource} loading="lazy" />
        )}
        <Info>
          <div>
            @{item.user.username} / {item.general.content_type}
          </div>
          {DateTime.fromMillis(item.taken_at).toFormat('yyyy-MM-dd HH:mm:ss')}
          {DEV_FEATURE && (
            <>
              <br />
              {item.id}
            </>
          )}
        </Info>
      </Box>
    </>
  );
};
