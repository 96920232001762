import { Global, css, ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';

const PensiveGlobalStyle = css`
  html,
  body {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    position: sticky !important;


    // overflow-x: hidden;
  }
  /* http://meyerweb.com/eric/tools/css/reset/ 
  v2.0 | 20110126
  License: none (public domain)
*/

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
  }
  #root {
    width: 100%;
    height: 100%;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  * {
    box-sizing: border-box;
  }

  .ReactModalPortal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

  .Modal {
    // display: inline-block;
  }

  .Overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-overflow-scrolling: touch;
  }
`;
export const PensiveContainer = styled.div`
  width: 100%;
  height: 100%;
  // height: 100vh;
`;
const theme = {};

export const PensiveTheme: FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <Global styles={PensiveGlobalStyle} />
      {children}
    </>
  </ThemeProvider>
);
