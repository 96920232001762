import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Modal } from '@mui/material';
import { DateTime, Info } from 'luxon';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

export const Typography = {
  header: css`
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #212121;

    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  `,
  item: css`
    font-size: 16px;
    text-align: center;
    color: #cecece;

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  `,
};
const ModalContainer = styled(Box)`
  width: 320px;
  padding: 16px;
  outline: transparent;
  box-shadow: #dfdfdf 0 0 15px 5px;
  border-radius: 3px;

  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);

  background-color: white;

  @media screen and (max-width: 600px) {
    width: 260px;
    top: 90px;
  }
`;

const LayoutContainer = styled.div``;

const YearConainter = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  ${Typography.header};

  & svg {
    vertical-align: middle;
    margin: 0 16px;
    fill: #212121;
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 16px;

    & svg {
      width: 0.6em;
    }
  }
`;
export const Label = styled.p`
  flex: 1;
`;
const MonthConainter = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
  height: 100%;
`;

const BORDER_RADIUS = '3px';
const MonthItem = styled.div`
  padding: 16px 0;
  text-align: center;
  cursor: pointer;
  ${Typography.item}

  border: 1px solid #cecece;
  &:nth-child(1) {
    border-top-left-radius: ${BORDER_RADIUS};
  }
  &:nth-child(2),
  &:nth-child(3) {
    margin-left: -1px;
  }
  &:nth-child(4) {
    border-top-right-radius: ${BORDER_RADIUS};
    margin-left: -1px;
  }
  &:nth-child(5) {
    margin-top: -1px;
  }
  &:nth-child(6),
  &:nth-child(7),
  &:nth-child(8) {
    margin-top: -1px;
    margin-left: -1px;
  }
  &:nth-child(9) {
    margin-top: -1px;
    border-bottom-left-radius: ${BORDER_RADIUS};
  }
  &:nth-child(10),
  &:nth-child(11) {
    margin-top: -1px;
    margin-left: -1px;
  }
  &:nth-child(12) {
    border-bottom-right-radius: ${BORDER_RADIUS};
    margin-top: -1px;
    margin-left: -1px;
  }

  :hover {
    border-color: #343434;
    color: #343434;
    z-index: 1;
  }

  @media screen and (max-width: 600px) {
    padding: 8px 0;
  }
`;

export const MonthSelector = ({
  currentDateTime = DateTime.now(),
  onSelect,
  onClose,
}: {
  currentDateTime?: DateTime;
  onSelect: (newDate: DateTime) => void;
  onClose: () => void;
}): JSX.Element => {
  const [datetime, setDatetime] = useState(currentDateTime);
  const handlePrevYear = () => setDatetime(prev => prev.minus({ year: 1 }));
  const handleNextYear = () => setDatetime(prev => prev.plus({ year: 1 }));

  const handleSelect = (month: string) => {
    const selected = DateTime.fromFormat(`${month} ${datetime.year}`, 'LLL yyyy');
    onSelect(selected);
    onClose();
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      tabIndex={-1}
      disableEnforceFocus
      disablePortal
      disableRestoreFocus
      onBackdropClick={onClose}
      BackdropProps={{
        sx: { backgroundColor: 'transparent' },
      }}
    >
      <ModalContainer>
        <LayoutContainer>
          <YearConainter>
            <ArrowBackIosNewIcon onClick={handlePrevYear} />
            <Label>{datetime.year}</Label>
            <ArrowForwardIosIcon onClick={handleNextYear} />
          </YearConainter>
          <MonthConainter>
            {Info.months('short').map(month => (
              <MonthItem key={month} role="button" onClick={() => handleSelect(month)}>
                {month}
              </MonthItem>
            ))}
          </MonthConainter>
        </LayoutContainer>
      </ModalContainer>
    </Modal>
  );
};
