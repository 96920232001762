import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CalendarContainer = styled.div`
  margin-top: 8px;
`;

export const Typography = {
  header: css`
    font-size: 24px;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  `,
};

export const ActoinBox = styled.div`
  position: relative;
  
`;

export const MonthHeader = styled.div`
  width: 100%;
  margin: 24px 0;

  & svg {
    vertical-align: middle;
    margin: 0 16px;
    fill: #212121;
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    margin: 12px 0;
    & svg {
      height: 16px;
    }
  }

  ${Typography.header};
`;
export const Label = styled.div`
  display: inline-block;
  width: 250px;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    width: 150px;
  }
`;

export const Week = styled.div<{ header?: boolean }>`
  width: 100%;
  min-height: ${p => (p.header ? 48 : 96)}px;
  display: flex;
  align-items: top;
  text-align: ${p => (p.header ? 'center' : 'left')};

  @media screen and (max-width: 600px) {
    min-height: ${p => (p.header ? 32 : 48)}px;
    font-size: 14px;
  }
`;

export const Day = styled.div<{ hasData?: boolean; day?: number }>`
  ${({ day, hasData }) => css`
    flex: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    ${hasData &&
    css`
      cursor: pointer;
    `}

    &::before {
      content: '${day}';
      position: absolute;
      font-weight: 700;
      ${hasData
        ? css`
            color: white;
            text-shadow: #212121 0 0 3px;
          `
        : css`
            color: #cecece;
          `}
    }

    & img {
      object-fit: cover;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      @media screen and (max-width: 600px) {
        width: 32px;
        height: 32px;
      }
    }
  `}
`;
