import { useGetAllByMonth } from 'api/list';
import { ContentEntity } from 'api/types';
import Calendar from 'components/calendar';
import { GalleryOfDay } from 'components/calendar/Gallery';
import { OverlayLoader } from 'components/common/Loader';
import { DefaultLayout } from 'components/layout/styles';
import { ModalWrapper } from 'components/modal';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';

export default function CalendarView({ active }: { active: boolean }) {
  const [month, setMonth] = useState(DateTime.fromMillis(Date.now()).toFormat('yyyy-MM'));
  const { results, loading, error, request } = useGetAllByMonth(month);
  const handleMonth = useCallback((month: string) => {
    setDate(null);
    setMonth(month);
  }, []);

  if (error) {
    console.error(error);
  }

  const [selected, setDate] = useState<{ date: string; data: Array<ContentEntity> } | null>();
  const handleSelect = useCallback((time: DateTime, data: Array<ContentEntity>) => {
    setDate({ date: time.toFormat('yyyy MM dd'), data });
  }, []);

  const [entity, setEntity] = useState<ContentEntity | null>();
  const handleOpen = useCallback((current: ContentEntity) => {
    setEntity(current);
  }, []);
  const handleClose = () => {
    setEntity(null);
  };

  useEffect(() => {
    if (!results && active) request();
  }, [request, results, active]);

  return (
    <DefaultLayout active={active}>
      {loading && <OverlayLoader />}
      <Calendar data={results} loading={loading} handleMonth={handleMonth} onSelect={handleSelect} />
      {selected && <GalleryOfDay date={selected.date} entities={selected.data} onSelect={handleOpen} />}
      {!!entity && <ModalWrapper entity={entity} onClose={handleClose} />}
    </DefaultLayout>
  );
}
