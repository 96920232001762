import styled from '@emotion/styled';
import { Button, Container, Stack } from '@mui/material';
import { useGetAll } from 'api/GetAll';
import { useGetAllByMonth } from 'api/GetAllByMonth';
import { DefaultLayout } from 'components/layout/styles';
import { useCalendar, useMonth } from 'hooks/useCalendar';
import { DateTime } from 'luxon';
import { memo, useCallback, useState } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const Pre = styled.pre`
  // max-height: 300px;
  overflow: auto;
  font-size: 12px;
`;

enum ViewTypes {
  useMonth,
  useCalendar,
  CalendarView,
  GalleryView,
}
const debugViewState = atom({
  key: 'debug-view-selector',
  default: ViewTypes.useCalendar,
});

export default function DebugView({ active }: { active: boolean }) {
  const currentView = useRecoilValue(debugViewState);
  return (
    <DefaultLayout active={active}>
      <ViewSelector />
      <div>
        {currentView === ViewTypes.useMonth && <UseMonthDebug />}
        {currentView === ViewTypes.useCalendar && <UseCalendarDebug />}
        {currentView === ViewTypes.CalendarView && <CalendarDebug />}
        {currentView === ViewTypes.GalleryView && <GalleryDebug />}
      </div>
    </DefaultLayout>
  );
}

const ViewSelector = () => {
  const setter = useSetRecoilState(debugViewState);
  return (
    <nav>
      <Button onClick={() => setter(ViewTypes.useMonth)}>useMonth</Button>
      <Button onClick={() => setter(ViewTypes.useCalendar)}>useCalendar</Button>
      <Button onClick={() => setter(ViewTypes.CalendarView)}>CalendarView</Button>
      <Button onClick={() => setter(ViewTypes.GalleryView)}>GalleryView</Button>
    </nav>
  );
};
const UseMonthDebug = memo(() => {
  const { info, next, prev } = useMonth();
  return (
    <Container>
      <Stack spacing={2} direction="row">
        <Button onClick={() => prev('months')}>Prev</Button>
        <Button onClick={() => next('months')}>Next</Button>
      </Stack>
      <h3>info</h3>
      <Pre>{JSON.stringify(info, null, 2)}</Pre>
    </Container>
  );
});
const UseCalendarDebug = memo(() => {
  const { monthInfo, days, next, prev } = useCalendar();
  return (
    <Container>
      <Stack spacing={2} direction="row">
        <Button onClick={() => prev('months')}>Prev</Button>
        <Button onClick={() => next('months')}>Next</Button>
      </Stack>
      <h3>monthInfo</h3>
      <Pre>{JSON.stringify(monthInfo, null, 2)}</Pre>
      <h3>days</h3>
      <Pre>{JSON.stringify(days, null, 2)}</Pre>
    </Container>
  );
});

const CalendarDebug = memo(() => {
  const [month, setMonth] = useState(DateTime.fromMillis(Date.now()));
  const { results, loading, error } = useGetAllByMonth(month.toFormat('yyyy-MM'));

  const handlePrev = useCallback(() => {
    setMonth(curr => curr.minus({ months: 1 }));
  }, []);
  const handleNext = useCallback(() => {
    setMonth(curr => curr.plus({ months: 1 }));
  }, []);
  return (
    <Container>
      <h1>CalendarDebug</h1>
      <Stack spacing={2} direction="row">
        <h2>{month.toFormat('yyyy-MM')}</h2>
        <Button variant="contained" onClick={handlePrev}>
          prev
        </Button>
        <Button variant="contained" onClick={handleNext}>
          next
        </Button>
      </Stack>
      <h3>loading</h3>
      <Pre>{JSON.stringify(loading, null, 2)}</Pre>
      <h3>error</h3>
      <Pre>{JSON.stringify(error, null, 2)}</Pre>
      <h3>results</h3>
      <Pre>{JSON.stringify(results, null, 2)}</Pre>
    </Container>
  );
});

const GalleryDebug = memo(() => {
  const { results, loading, error, next } = useGetAll();
  const handleNext = useCallback(() => {
    next();
  }, [next]);
  return (
    <Container>
      <h1>GalleryDebug</h1>
      <Stack spacing={2} direction="row">
        <Button variant="contained" disabled={loading} onClick={handleNext}>
          next
        </Button>
      </Stack>
      <h3>loading</h3>
      <Pre>{JSON.stringify(loading, null, 2)}</Pre>
      <h3>error</h3>
      <Pre>{JSON.stringify(error, null, 2)}</Pre>
      <h3>results</h3>
      <Pre>{JSON.stringify(results, null, 2)}</Pre>
    </Container>
  );
});
