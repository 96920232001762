import styled from '@emotion/styled';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Skeleton } from '@mui/material';
import { GetAllByMonthEntity } from 'api/GetAllByMonth';
import { ContentEntity, Post, Story } from 'api/types';
import { useCalendar } from 'hooks/useCalendar';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MonthSelector } from './Selector';
import { ActoinBox, CalendarContainer, Day, Label, MonthHeader, Week } from './style';

const Contaier = styled.div`
  position: relative;
`;

type Props = {
  data?: GetAllByMonthEntity;
  loading: boolean;
  handleMonth: (month: string) => void;
  onSelect: (time: DateTime, list: Array<ContentEntity>) => void;
};
export default function Calendar({ data, handleMonth, onSelect }: Props) {
  const { monthInfo, days, next, prev, update } = useCalendar();
  const monthString = monthInfo.day.toFormat('yyyy-MM');

  useEffect(() => {
    handleMonth(monthString);
  }, [handleMonth, monthString]);

  const attached = days.map(day => ({
    time: day,
    data: data?.[day.day],
  }));

  const index = attached.findIndex((info, index) => info.time.weekday % 7 === 0);
  const prePad = new Array(7 - index).fill('');

  const first = prePad.concat(attached.splice(0, index));
  const second = attached.splice(0, 7);
  const third = attached.splice(0, 7);
  const fourth = attached.splice(0, 7);
  const fifth = attached.splice(0, 7);
  const sixth = attached.splice(0, 7);

  const postPad1 = new Array(7 - fifth.length).fill('');
  const postPad2 = new Array(7 - sixth.length).fill('');

  const [activeModal, toggleActiveModal] = useState(false);

  const handleUpdateDate = (newDate: DateTime) => {
    update({
      day: newDate,
    });
  };

  return (
    <CalendarContainer>
      <ActoinBox>
        <MonthHeader>
          <ArrowBackIosIcon onClick={() => prev('months')} />
          <Label onClick={() => toggleActiveModal(true)}>{monthInfo.day.toFormat('LLLL yyyy')}</Label>
          <ArrowForwardIosIcon onClick={() => next('months')} />
        </MonthHeader>
        {activeModal && (
          <MonthSelector
            currentDateTime={monthInfo.firstDay}
            onSelect={handleUpdateDate}
            onClose={() => toggleActiveModal(false)}
          />
        )}
      </ActoinBox>

      <Week header>
        <Day>Sun</Day>
        <Day>Mon</Day>
        <Day>Tue</Day>
        <Day>Wed</Day>
        <Day>Thu</Day>
        <Day>Fri</Day>
        <Day>Sat</Day>
      </Week>

      <Contaier>
        {!days && (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              backgroundColor: 'rgba(255,255,255,.5)',
              zIndex: 1,
            }}
          />
        )}
        {index > 0 && (
          <Week>
            {first.map(({ time, data }, index) => (
              <Date key={`first_${index}`} time={time} data={data} onClick={onSelect} />
            ))}
          </Week>
        )}
        <Week>
          {second.map(({ time, data }, index) => (
            <Date key={`second_${index}`} time={time} data={data} onClick={onSelect} />
          ))}
        </Week>
        <Week>
          {third.map(({ time, data }, index) => (
            <Date key={`third_${index}`} time={time} data={data} onClick={onSelect} />
          ))}
        </Week>
        <Week>
          {fourth.map(({ time, data }, index) => (
            <Date key={`fourth_${index}`} time={time} data={data} onClick={onSelect} />
          ))}
        </Week>
        {postPad1.length < 7 && (
          <Week>
            {fifth.map(({ time, data }, index) => (
              <Date key={`fifth_${index}`} time={time} data={data} onClick={onSelect} />
            ))}
            {postPad1.map((_, index) => (
              <Day key={`post1-${index}`}></Day>
            ))}
          </Week>
        )}
        {postPad2.length < 7 && (
          <Week>
            {sixth.map(({ time, data }, index) => (
              <Date key={`sixth_${index}`} time={time} data={data} onClick={onSelect} />
            ))}
            {postPad2.map((_, index) => (
              <Day key={`post2-${index}`}></Day>
            ))}
          </Week>
        )}
      </Contaier>
    </CalendarContainer>
  );
}

const Date = ({
  time,
  data,
  onClick,
}: {
  time?: DateTime;
  data?: Array<ContentEntity>;
  onClick: (time: DateTime, list: Array<ContentEntity>) => void;
}) => {
  const hasData = data && data?.length > 0;
  const handleClick = useCallback(() => {
    if (time && data) onClick(time, data);
  }, [data, onClick, time]);
  const url = useMemo(() => {
    const item = data?.[0];
    if (item) {
      if (item.general.content_type === 'post') {
        return (item as Post).media[0].thumbnail;
      } else {
        return (item as Story).thumbnail;
      }
    }
  }, [data]);
  return (
    <Day day={time?.day} hasData={hasData} onClick={handleClick}>
      {hasData && <img src={url} alt="" loading="lazy" />}
    </Day>
  );
};
