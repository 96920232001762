import React from 'react';
import styled from 'styled-components';

export const SpinnerLoader = (): JSX.Element => (
  <svg width="48" height="48" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#a9a9a9">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".2" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur=".7s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
);

const OverlayContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
`;
export const OverlayLoader = () => (
  <OverlayContainer>
    <SpinnerLoader />
  </OverlayContainer>
);
