import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';

type Params = {
  day: [string, string] | DateTime;
};
type UnitType = 'years' | 'months' | 'days';
export type MonthInfo = {
  day: DateTime;
  firstDay: DateTime;
  lastDay: DateTime;
};

const getFirstDay = (d: DateTime) => DateTime.fromFormat(`${d.toFormat('yyyy-MM')}-01`, 'yyyy-MM-dd');
const getLastDay = (d: DateTime) =>
  DateTime.fromFormat(`${d.toFormat('yyyy-MM')}-01`, 'yyyy-MM-dd')
    .plus({ months: 1 })
    .minus({ days: 1 });
const buildResult = (d: DateTime) => ({
  day: d,
  firstDay: getFirstDay(d),
  lastDay: getLastDay(d),
});
export function useMonth(param?: Params) {
  const day = param?.day;
  const _day = useMemo(() => {
    if (day instanceof Array) {
      const [value, format] = day;
      try {
        if (!format) throw new Error('format should be assigned: ' + format);
        return DateTime.fromFormat(value, format);
      } catch (e) {
        throw new Error('Invalid format: ' + format);
      }
    }
    return day;
  }, [day]);

  const [dayObject, setDay] = useState<DateTime>(_day || DateTime.now());
  const firstDay = getFirstDay(dayObject);
  const lastDay = getLastDay(dayObject);

  const next = (unit: UnitType) => {
    const day = dayObject.plus({ [unit]: 1 });
    setDay(day);
    return buildResult(day);
  };
  const prev = (unit: UnitType) => {
    const day = dayObject.minus({ [unit]: 1 });
    setDay(day);
    return buildResult(day);
  };
  const update = (param: Params) => {
    const newDay = param.day;
    if (newDay instanceof Array) {
      const [value, format] = newDay;
      try {
        if (!format) throw new Error('format should be assigned: ' + format);
        setDay(DateTime.fromFormat(value, format));
      } catch (e) {
        throw new Error('Invalid format: ' + format);
      }
    } else {
      setDay(newDay);
    }
  };

  return { info: { day: dayObject, firstDay, lastDay }, next, prev, update };
}

export function useCalendar(param?: Params) {
  const { info, next, prev, update } = useMonth(param);

  const monthInfo = buildResult(info.day);
  const lastDay = info.lastDay.day;
  const days = useMemo(() => {
    const days: DateTime[] = [];
    let currentDay = info.firstDay;
    for (let day = 1; day <= lastDay; day++) {
      days.push(currentDay);
      currentDay = currentDay.plus({ days: 1 });
    }
    return days;
  }, [info.firstDay, lastDay]);

  return {
    monthInfo,
    days,
    next,
    prev,
    update,
  };
}
