import { StyledEngineProvider } from '@mui/material/styles';
import { GNB, NavItems } from 'components/layout/GNB';
import { initializeFirebase } from 'gcp/Analytics';
import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { hot } from 'react-hot-loader/root';
import ReactModal from 'react-modal';
import { RecoilRoot } from 'recoil';
import { PensiveContainer, PensiveTheme } from 'theme/global';
import CalendarView from 'views/calendar';
import DebugView from 'views/debug';
import GalleryView from 'views/gallery';
import reportWebVitals from './reportWebVitals';

const MyApp = () => {
  const debug = process.env.NODE_ENV === 'development';
  const [nav, setNav] = useState<NavItems>(debug ? NavItems.Debug : NavItems.Gallery);
  const handleSelect = useCallback((current: NavItems) => {
    setNav(current);
  }, []);
  return (
    <RecoilRoot>
      <StyledEngineProvider injectFirst>
        <PensiveTheme>
          <PensiveContainer>
            <GNB selected={nav} debug={debug} onSelect={handleSelect} />
            {<GalleryView active={nav === NavItems.Gallery} />}
            {<CalendarView active={nav === NavItems.Calendar} />}

            {debug && <DebugView active={nav === NavItems.Debug} />}
          </PensiveContainer>
        </PensiveTheme>
      </StyledEngineProvider>
    </RecoilRoot>
  );
};

const DEV_FEATURE = process.env.NODE_ENV === 'development';
const App = DEV_FEATURE ? hot(MyApp) : MyApp;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
if (!DEV_FEATURE) initializeFirebase();
ReactModal.setAppElement('#root');
