import styled from '@emotion/styled';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import BuildIcon from '@mui/icons-material/Build';
import { css } from '@emotion/react';

export enum NavItems {
  Gallery = 'Gallery',
  Calendar = 'Calendar',
  Debug = 'Debug',
}
const Shell = styled.div`
  width: 100%;
  position: fixed;
  z-index: 1;
  background-color: rgba(255,255,255, .5);
  top: 0;
`;
const Nav = styled.nav`
  width: 100%;
  display: flex;
  position: relative;
`;
const Marker = styled.div<{ selected: NavItems; debug: boolean }>`
  background-color: #212121;
  position: absolute;
  bottom: 0;
  left: ${({ selected }) => (selected === NavItems.Gallery ? '0' : '50%')};
  width: 50%;
  height: 2px;
  transition: 150ms left ease-out;
  ${({ debug, selected }) => {
    if (debug) {
      switch (selected) {
        case NavItems.Gallery:
          return css`
            left: 0;
            width: 33%;
          `;
        case NavItems.Calendar:
          return css`
            left: 33%;
            width: 33%;
          `;
        case NavItems.Debug:
          return css`
            left: 66%;
            width: 33%;
          `;
      }
    } else {
      if (selected === NavItems.Gallery) {
        return css`
          left: 0;
          width: 50%;
        `;
      } else {
        return css`
          left: 50%;
          width: 50%;
        `;
      }
    }
  }};
`;
const Item = styled.div<{ selected: boolean }>`
  flex: 1;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #efefef;
  @media screen and (max-width: 600px) {
    padding: 4px;
    svg {
      width: .85em;
      height: .85em;
    }
  }
`;

type Props = { selected: NavItems; debug: boolean; onSelect: (nav: NavItems) => void };
export const GNB = ({ selected, debug, onSelect }: Props) => (
  <Shell>
    <Nav>
      <Item selected={selected === NavItems.Gallery} onClick={() => onSelect(NavItems.Gallery)}>
        <AvTimerIcon />
      </Item>
      <Item selected={selected === NavItems.Calendar} onClick={() => onSelect(NavItems.Calendar)}>
        <DateRangeIcon />
      </Item>
      {debug && (
        <Item selected={selected === NavItems.Debug} onClick={() => onSelect(NavItems.Debug)}>
          <BuildIcon />
        </Item>
      )}
      <Marker selected={selected} debug={debug} />
    </Nav>
  </Shell>
);
