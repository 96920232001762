import styled from '@emotion/styled';
import { Youtube } from 'api/types';
import { DateTime } from 'luxon';
import React, { useRef } from 'react';
import { BodyScrollLock } from './BodyScrollLock';

const InfoItemBox = styled.div`
  display: flex;
  border-radius: 0 0 5px 5px;
  width: 95vw;
  height: 98vh;
  box-shadow: rgb(0 0 0 / 50%) 0 0 10px;
  @media (max-width: 1200px) {
    flex-direction: column;
    width: 80vw;
  }
  @media (max-width: 750px) {
    height: 75vh;
  }

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }
  .slick-slide > div {
    text-align: center;
  }
  .slick-arrow {
    z-index: 1;
  }
  .slick-dots li {
    margin: 0;
    bottom: 50px;
  }
  .slick-dots li button:before {
    color: white;
    font-size: 8px;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
  }

  @media (max-width: 750px) {
    .slick-dots li {
      margin: 0;
      bottom: 25px;
    }

    .slick-dots li button:before {
      color: white;
      font-size: 6px;
    }
  }
`;
const Description = styled.div`
  font-size: 14px;
  line-height: 1.6;
  span {
    font-weight: bold;
    padding-right: 4px;
  }
  span + span {
    font-weight: normal;
  }
`;
const DateTimeItem = styled.div`
  font-size: 10px;
  letter-spacing: 0.2px;
  line-height: 18px;
  color: rgba(var(--f52, 142, 142, 142), 1);
`;

const MedieaItem = styled.div`
  width: calc(100% - 405px);
  height: 100%;
  background-color: black;
  position: relative;

  .slick-prev {
    left: 25px;
  }
  .slick-next {
    right: 25px;
  }

  @media (max-width: 1200px) {
    width: 100%;
    height: 50vh;

    .slick-prev {
      left: -25px;
    }
    .slick-next {
      right: -25px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const VideoBox = styled.div`
  // width: 100%;
  // height: 60vh;
  height: 100%;
  object-fit: contain;
  @media (max-width: 1200px) {
    height: 50vh;
  }
`;

const InfoBox = styled.div`
  overflow: auto;
  padding: 16px;
  width: 405px;

  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }

  a {
    word-break: break-all;
  }
`;
const Caption = styled.div`
  margin-top: 24px;
`;

const DEV_FEATURE = process.env.NODE_ENV === 'development';

export const YoutubeItem = ({ item }: { item: Youtube }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const embedUrl = item.embedUrl.replace('width="480"', 'width="100%"').replace('height="270"', 'height="100%"');

  return (
    <InfoItemBox>
      <BodyScrollLock allowedElement={divRef} />
      <MedieaItem>
        <VideoBox dangerouslySetInnerHTML={{ __html: embedUrl }} />
      </MedieaItem>
      <InfoBox ref={divRef}>
        <Description>
          <h2>{item.title}</h2>
          <DateTimeItem>{DateTime.fromMillis(item.taken_at).toFormat('yyyy-MM-dd HH:mm:ss')}</DateTimeItem>
          <h4>{item.channelTitle}</h4>
          <Caption
            dangerouslySetInnerHTML={{
              __html: item.description.replaceAll('\n', '<br/>'),
            }}
          />
        </Description>
        {DEV_FEATURE && <DateTimeItem>{item.url}</DateTimeItem>}
      </InfoBox>
    </InfoItemBox>
  );
};
