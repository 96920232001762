import styled from '@emotion/styled';

export const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-flow: row dense;
  gap: 1px;
  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const GridItem = styled.div<{ publisher?: 'instagram' | 'youtube' }>`
  position: relative;
  cursor: pointer;
  &:hover {
    & > div {
      display: block;
    }
  }

  ${({ publisher }) => (publisher === 'youtube' ? 'grid-column-end : span 2;' : '')};
`;
export const Thumbnail = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
export const Caption = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 14px;
  padding: 4px;
  box-sizing: border-box;

  display: none;
  @media screen and (max-width: 600px) {
    font-size: 10px;
  }
`;
export const Id = styled.div`
  display: block;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
