import styled from '@emotion/styled';
import { ContentEntity, Post, Reel, Story, TV, Youtube } from 'api/types';
import { InstagramPostItem } from 'components/modal/Post';
import { InstagramStoryItem } from 'components/modal/Story';
import { useCallback } from 'react';
import ReactModal from 'react-modal';
import { YoutubeItem } from './Youtube';

const FadeBox = styled.div`
  display: inline-flex;
  background-color: white;
  max-height: 100vh;
  @media (max-width: 750px) {
    height: 75vh;
  }
`;

type Props = {
  entity: ContentEntity;
  onOpen?: () => void;
  onClose?: () => void;
};
export const ModalWrapper = ({ entity, onOpen, onClose }: Props) => {
  const handleOpen = useCallback(() => {
    if (onOpen) onOpen();
  }, [onOpen]);
  const handleClose = useCallback(() => {
    document.body.style.overflow = 'unset';
    if (onClose) onClose();
  }, [onClose]);

  return (
    <ReactModal
      isOpen={!!entity}
      onRequestClose={handleClose}
      onAfterOpen={handleOpen}
      contentLabel="Minimal Modal Example"
      className="Modal"
      overlayClassName="Overlay"
      shouldFocusAfterRender={false}
    >
      <FadeBox>
        {entity &&
          (entity.general.content_type === 'post' ? (
            <InstagramPostItem item={entity as Post} />
          ) : entity.general.content_type === 'reel' ? (
            <InstagramPostItem item={entity as Reel} />
          ) : entity.general.content_type === 'tv' ? (
            <InstagramPostItem item={entity as TV} />
          ) : entity.general.content_type === 'story' ? (
            <InstagramStoryItem item={entity as Story} />
          ) : entity.general.content_type === 'vod' ? (
            <YoutubeItem item={entity as Youtube} />
          ) : <div />)
        }
      </FadeBox>
    </ReactModal>
  );
};
