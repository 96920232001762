// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

export const initializeFirebase = () => {
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional

  try{

    const apiKey = "AIzaSyDXL7E9J2QKG5SXEWVra1sGpFZG_wu82eI";//process.env.REACT_APP_FB_apiKey as string;
    const authDomain = "davichive.firebaseapp.com";//process.env.REACT_APP_FB_authDomain as string;
    const projectId = "davichive"; //process.env.REACT_APP_FB_projectId as string;
    const storageBucket = "davichive.appspot.com"; //process.env.REACT_APP_FB_storageBucket as string;
    const messagingSenderId = "632367044897";  // process.env.REACT_APP_FB_messagingSenderId as string;
    const appId = "1:632367044897:web:52ac5ccf27aab870fe4660";//process.env.REACT_APP_FB_appId as string;
    const measurementId = "G-3JN27BQNCP";//process.env.REACT_APP_FB_measurementId as string;
  
    const firebaseConfig = {
      apiKey,
      authDomain,
      projectId,
      storageBucket,
      messagingSenderId,
      appId,
      measurementId
    };
  
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
  
    logEvent(analytics, 'notification_received');
  } catch {
    console.error('firebase initializing is failed')
  }
};
