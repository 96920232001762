import styled from '@emotion/styled';
import { ContentEntity } from 'api/types';
import Gallery from '../gallery/index';

type Props = {
  date: string;
  entities: ContentEntity[];
  onSelect: (item: ContentEntity) => void;
};

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgb(228, 228, 228);
`;
const Date = styled.h3`
  margin: 16px;
  span {
    font-weight: 300;
    font-size: 14px;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
    span {
      font-weight: 300;
      font-size: 12px;
    }
  }
`;
export const GalleryOfDay = ({ date, entities, onSelect }: Props): JSX.Element => (
  <ListBox>
    <Date>
      {date} <span>({entities.length})</span>
    </Date>
    <Gallery data={entities} onSelect={onSelect} />
  </ListBox>
);
