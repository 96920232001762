import { useGetAll } from 'api/list';
import Gallery from 'components/gallery';
import { DefaultLayout } from 'components/layout/styles';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import { ModalWrapper } from 'components/modal';
import { useCallback, useEffect, useState } from 'react';
import { ContentEntity } from 'api/types';

export default function GalleryView({ active }: { active: boolean }) {
  const { results, loading, error, request, next } = useGetAll();

  if (error) {
    console.error(error);
  }

  const [entity, setEntity] = useState<ContentEntity | null>();
  const handleOpen = useCallback((current: ContentEntity) => {
    setEntity(current);
  }, []);
  const handleClose = () => {
    setEntity(null);
  };

  useEffect(() => {
    if (!results && active) request();
  }, [request, results, active]);

  return (
    <DefaultLayout active={active}>
      <Gallery data={results} loading={loading} onSelect={handleOpen} />
      <ButtonBox>
        <Button onClick={next} disabled={loading}>
          {loading ? 'Loading...' : 'Load more'}
        </Button>
      </ButtonBox>
      {!!entity && <ModalWrapper entity={entity} onClose={handleClose} />}
    </DefaultLayout>
  );
}

const ButtonBox = styled.div`
  width: 100%;
  padding: 16px 0;
  text-align: center;
`;
