import { useState, useEffect, MutableRefObject, Dispatch, SetStateAction, useLayoutEffect } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const getTargetElement = (element?: string | MutableRefObject<HTMLElement | null>) => {
  let targetElement: HTMLElement | null = document.querySelector('body');
  if (typeof element === 'string') {
    targetElement = document.querySelector(element); // selector
  } else {
    if (element && element.current) targetElement = element.current; // ref.current
  }
  return targetElement;
};

const useBodyScrollLock = (
  allowedElement: string | MutableRefObject<HTMLElement | null>
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [isLocked, setLock] = useState(false);

  useEffect(() => {
    const targetElement = getTargetElement(allowedElement);
    if (targetElement) {
      if (isLocked) {
        disableBodyScroll(targetElement);
      } else {
        enableBodyScroll(targetElement);
      }
    }
  }, [isLocked, allowedElement]);

  useLayoutEffect(() => {
    return () => {
      const targetElement = getTargetElement(allowedElement);
      if (targetElement) enableBodyScroll(targetElement);
      clearAllBodyScrollLocks();
    };
  }, []);

  return [isLocked, setLock];
};

export const BodyScrollLock = ({
  allowedElement,
}: {
  allowedElement: string | MutableRefObject<HTMLElement | null>;
}): JSX.Element => {
  //@ts-ignore
  const [, setLock] = useBodyScrollLock(allowedElement);

  useEffect(() => {
    setLock(true);
    return () => {
      setLock(false);
    };
  }, [allowedElement, setLock]);

  return <></>;
};
