import styled from '@emotion/styled/macro';
import { Post, Reel, TV } from 'api/types';
import { DateTime } from 'luxon';
import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { BodyScrollLock } from './BodyScrollLock';

const InfoItemBox = styled.div`
  display: flex;
  border-radius: 0 0 5px 5px;
  width: 95vw;
  height: 98vh;
  box-shadow: rgb(0 0 0 / 50%) 0 0 10px;
  @media (max-width: 1200px) {
    flex-direction: column;
    width: 80vw;
  }
  @media (max-width: 750px) {
    height: 75vh;
  }

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }
  .slick-slide > div {
    text-align: center;
  }
  .slick-arrow {
    z-index: 1;
  }
  .slick-dots li {
    margin: 0;
    bottom: 50px;
  }
  .slick-dots li button:before {
    color: white;
    font-size: 8px;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
  }

  @media (max-width: 750px) {
    .slick-dots li {
      margin: 0;
      bottom: 25px;
    }

    .slick-dots li button:before {
      color: white;
      font-size: 6px;
    }
  }
`;
const Description = styled.div`
  font-size: 14px;
  line-height: 1.6;
  span {
    font-weight: bold;
    padding-right: 4px;
  }
  span + span {
    font-weight: normal;
  }
`;
const DateTimeItem = styled.div`
  font-size: 10px;
  letter-spacing: 0.2px;
  line-height: 18px;
  color: rgba(var(--f52, 142, 142, 142), 1);
`;

const MedieaItem = styled.div`
  width: calc(100% - 405px);
  height: 100%;
  background-color: black;
  position: relative;

  .slick-prev {
    left: 25px;
  }
  .slick-next {
    right: 25px;
  }

  @media (max-width: 1200px) {
    width: 100%;
    height: 50vh;

    .slick-prev {
      left: -25px;
    }
    .slick-next {
      right: -25px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const Image = styled.img`
  // width: 100%;
  // height: 60vh;
  height: 100%;
  object-fit: contain;
  @media (max-width: 1200px) {
    height: 50vh;
  }
`;
const Video = styled.video`
  // width: 100%;
  // height: 60vh;
  height: 100%;
  object-fit: contain;
  @media (max-width: 1200px) {
    height: 50vh;
  }
`;

const InfoBox = styled.div`
  overflow: auto;
  padding: 16px;
  width: 405px;

  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }

  a {
    word-break: break-all;
  }
`;

const Comments = styled.ul`
  margin-top: 16px;
`;
const CommentItem = styled.li`
  span {
    font-size: 14px;
    font-weight: bold;
    padding-right: 4px;
  }
  span + span {
    font-weight: normal;
  }

  & > ${Comments} {
    margin-top: 0;
    margin-left: 16px;
  }
`;

const TVCover = styled.a`
  div {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    font-weight: bold;
    transition: opacity 150ms ease-in;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    div {
      opacity: 1;
    }
  }
`;

const DEV_FEATURE = process.env.NODE_ENV === 'development';
// const CommentWhiteList: RegExp = /huccikang|dlgofl85|iammingki|filmingki|davichi.official/;
export const InstagramPostItem = ({ item }: { item: Post | Reel | TV }): JSX.Element => {
  const settings = {
    dots: true,
    // dotsClass: "slick-dots slick-thumb",
    infinite: false,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const divRef = useRef<HTMLDivElement>(null);

  return (
    <InfoItemBox>
      <BodyScrollLock allowedElement={divRef} />
      <MedieaItem>
        {item.general.content_type === 'tv' ? (
          <TVCover href={`https://www.instagram.com/tv/${item.shortcode}/`} target="_blank" rel="noreferrer">
            <Image src={item.media[0].thumbnail} loading="lazy" />
            <div>Watch video</div>
          </TVCover>
        ) : (
          <Slider {...settings}>
            {item.media.map(media =>
              media.is_video ? (
                <Video key={media.id} src={media.resource} controls playsInline />
              ) : (
                <Image key={media.id} src={media.resource} loading="lazy" />
              )
            )}
          </Slider>
        )}
      </MedieaItem>
      <InfoBox ref={divRef}>
        <Description>
          <span>{item.user.username}</span>
          <span
            dangerouslySetInnerHTML={{
              __html: item.description.replaceAll('\n', '<br/>'),
            }}
          />
        </Description>
        <DateTimeItem>{DateTime.fromMillis(item.taken_at).toFormat('yyyy-MM-dd HH:mm:ss')}</DateTimeItem>
        {DEV_FEATURE && <DateTimeItem>https://instagram.com/p/{item.shortcode}/</DateTimeItem>}
        <Comments>
          {item.comments.map((comment, index) => (
            <CommentItem key={`${comment.created_at}_${index}`}>
              <span>{comment.owner.username}</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: comment.text.replaceAll('\n', '<br/>'),
                }}
              />
              <DateTimeItem>{DateTime.fromMillis(comment.created_at).toFormat('yyyy-MM-dd HH:mm:ss')}</DateTimeItem>
              <Comments>
                {comment.threaded_comments?.map((child, index) => (
                  <CommentItem key={`${child.created_at}_${index}`}>
                    <span>{child.owner.username}</span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: child.text.replaceAll('\n', '<br/>'),
                      }}
                    />
                    <DateTimeItem>{DateTime.fromMillis(child.created_at).toFormat('yyyy-MM-dd HH:mm:ss')}</DateTimeItem>
                  </CommentItem>
                ))}
              </Comments>
            </CommentItem>
          ))}
        </Comments>
      </InfoBox>
    </InfoItemBox>
  );
};
