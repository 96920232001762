import { useCallback, useState } from 'react';
import useSWR from 'swr';
import { ContentEntity } from './types';
import { fetcher } from './_ajax';

const options = {
  revalidateOnFocus: false,
  dedupingInterval: 60 * 1000
}


export type GetAllByMonthEntity = Record<string, Array<ContentEntity>>;
type Response = {
  results?: GetAllByMonthEntity;
  loading: boolean;
  error: any;
  request: () => void;
};
export const useGetAllByMonth = (month?: string): Response => {
  const [ flag, setFlag ] = useState(false);
  const { data, error } = useSWR<GetAllByMonthEntity>(flag ? `/getAllByMonth?month=${month}` : null, fetcher, options);
  
  // const results = parser(data);
  const request = useCallback(() => { setFlag(true) }, []);
  return {
    results: data,
    loading: !error && !data,
    error,
    request
  };
};