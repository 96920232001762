export interface Dimensions {
  height: number;
  width: number;
}
export interface Location {
  id: string;
  name: string;
  has_public_page: boolean;
  slug: string;
  address_json: string;
}

export interface Media {
  thumbnail: string;
  resource: string;
  id: string;
  accessibility_caption: string;
  shortcode: string;
  is_video: boolean;
  dimensions: Dimensions;
  tagged_user: User[];
}

export interface User {
  profile_pic_url: string;
  full_name?: string;
  username: string;
  id: string;
  is_verified?: boolean;
}

export type Comment = {
  id: string;
  text: string;
  created_at: number;
  owner: Omit<User, 'full_name'>;
  viewer_has_liked: boolean;
  threaded_comments: Comment[] | null;
};
// -----
export interface Post {
  description: string;
  taken_at: number;
  location: Location | null;
  comments: Comment[];
  general: {
    content_type: 'post';
    publisher: 'instagram';
  };
  media: Media[];
  shortcode: string;
  user: User;
  id: string;
}
export type Reel = Omit<Post, 'general'> & {
  general: {
    content_type: 'reel';
    publisher: 'instagram';
  };
};
export type TV = Omit<Post, 'general'> & {
  general: {
    content_type: 'tv';
    publisher: 'instagram';
  };
};
export interface Story {
  dimensions: Dimensions;
  mentions: any[];
  resource: string;
  thumbnail: string;
  user: User;
  general: {
    content_type: 'story';
    publisher: 'instagram';
  };
  id: string;
  mediaType: MEDIA_TYPE;
  taken_at: number;
}

export enum MEDIA_TYPE {
  Image = 1,
  Video = 2,
}

export type Youtube = {
  general: {
    content_type: 'vod';
    publisher: 'youtube';
  };
  taken_at: number;
  description: string;
  thumbnail: string;
  embedUrl: string;
  tags: string[];
  channelTitle: string;
  title: string;
  id: string;
  url: string;
  channelId: string;
  publishedAt: string;
};

export type ContentEntity = Post | Story | Reel | TV | Youtube;
