import { useCallback, useEffect, useState } from 'react';
import useSWRInfinite from 'swr/infinite';
import { Post, Story } from './types';
import { fetcher } from './_ajax';

type GetAllResponse = {
  results: Array<Post | Story>;
  next: number;
};
const PAGE_SIZE = 20;
const INITIAL_OFFSET = 2700000000;
export const useGetAll = () => {
  const [ flag, setFlag ] = useState(false);
  const { data, error, size, setSize } = useSWRInfinite<GetAllResponse>(
    (index: number, prev: GetAllResponse | null) => {
      if(!flag) return null;
      // 끝에 도달
      if (prev && !prev.results) return null;

      // 첫 페이지, `previousPageData`가 없음
      if (!prev) return `/getAll?limit=${PAGE_SIZE}&offset=${INITIAL_OFFSET}`;

      // API의 엔드포인트에 커서를 추가
      return `/getAll?limit=${PAGE_SIZE}&offset=${prev?.next}`;
    },
    fetcher
    // options
  );
  
  useEffect(() => {
    setSize(1);
  }, [setSize]);
  
  const request = useCallback(() => setFlag(true), []);
  const next = useCallback(() => {
    setSize(size + 1);
  }, [size, setSize]);

  const results = parseResults(data);

  return {
    results,
    loading: !error && !data,
    error,
    request,
    next,
  };
};
// const parseResults = (rawResults?: GetAllResponse['results']) => rawResults?.map(result => result).flat();
const parseResults = (rawResults?: GetAllResponse[]) => rawResults?.map(result => result.results).flat();
