const GCP_ADDRESS = process.env.REACT_APP_GCF_ROOT;
const BETA_FLAG = process.env.REACT_APP_STAGE === 'BETA';
const INITIAL_OFFSET = 2700000000;
let initialResponse: any = null;
export const fetcher = (url: string) => {
  const endpoint = `${GCP_ADDRESS}${url}`;
  if (url.includes(INITIAL_OFFSET + '') && initialResponse) return Promise.resolve(initialResponse);
  return fetch(endpoint, {
    method: 'GET'
  }).then(async res => {
    if(res.ok) {
      const response = await res.json();
      if (url.includes(INITIAL_OFFSET + '')) initialResponse = response;
      return response;
    }
  });
};
