import { Masonry } from '@mui/lab';
import { Skeleton } from '@mui/material';
import { ContentEntity, Post, Reel, Story, TV, Youtube } from 'api/types';
import { useCallback } from 'react';
import { Caption, GridBox, GridItem, Id, Thumbnail } from './styles';

const DEV_FEATURE = process.env.NODE_ENV === 'development';

type Props = {
  data?: Array<ContentEntity>;
  loading?: boolean;
  onSelect: (entity: ContentEntity) => void;
};

export function Gallery2({ data, loading, onSelect }: Props) {
  const handleClick = useCallback(
    (entity: ContentEntity) => {
      onSelect(entity);
    },
    [onSelect]
  );
  if (!data) return null;
  if (loading) {
    return (
      <GridBox>
        {new Array(10).fill(1).map((_, index: number) => (
          <GridItem key={`skeleton_${index}`}>
            <Skeleton variant="rectangular" height={200} />
          </GridItem>
        ))}
      </GridBox>
    );
  }
  return (
    <Masonry columns={{ xs: 3, sm: 4, md: 5 }} spacing={0}>
      {data.map(item => (
        <GridItem onClick={() => handleClick(item)} key={item.id}>
          {item.general.content_type === 'post' && <PostItem data={item as Post} />}
          {item.general.content_type === 'reel' && <PostItem data={item as Reel} />}
          {item.general.content_type === 'story' && <StoryItem data={item as Story} />}
        </GridItem>
      ))}
    </Masonry>
  );
}

export default function Gallery({ data, loading, onSelect }: Props) {
  const handleClick = useCallback(
    (entity: ContentEntity) => {
      onSelect(entity);
    },
    [onSelect]
  );
  if (loading) {
    return (
      <GridBox>
        {new Array(10).fill(1).map((_, index: number) => (
          <GridItem key={`skeleton_${index}`}>
            <Skeleton variant="rectangular" height={200} />
          </GridItem>
        ))}
      </GridBox>
    );
  }
  return (
    <GridBox>
      {data?.map(item => (
        <GridItem publisher={item.general.publisher} onClick={() => handleClick(item)} key={item.id}>
          {item.general.content_type === 'post' && <PostItem data={item as Post} />}
          {item.general.content_type === 'reel' && <PostItem data={item as Reel} />}
          {item.general.content_type === 'tv' && <PostItem data={item as TV} />}
          {item.general.content_type === 'story' && <StoryItem data={item as Story} />}
          {item.general.content_type === 'vod' && <YoutubeItem data={item as Youtube} />}
        </GridItem>
      ))}
    </GridBox>
  );
}

const PostItem = ({ data }: { data: Post | Reel | TV }) => (
  <>
    <Thumbnail src={data.media[0].thumbnail} alt="" loading="lazy" />
    <Caption>
      <div>
        @{data.user.username} / {data.general.content_type}
      </div>
      {DEV_FEATURE && <Id>{data.shortcode}</Id>}
    </Caption>
  </>
);

const StoryItem = ({ data }: { data: Story }) => (
  <>
    <Thumbnail src={data.thumbnail} alt="" loading="lazy" />
    <Caption>
      <div>
        @{data.user.username} / {data.general.content_type}
      </div>
      {DEV_FEATURE && <Id>{data.id}</Id>}
    </Caption>
  </>
);

const YoutubeItem = ({ data }: { data: Youtube }) => (
  <>
    <Thumbnail src={data.thumbnail} alt="" loading="lazy" />
    <Caption>
      <div>
        {data.channelTitle}
        <br />
        {data.title}
        {/* @{data.user.username} / {data.general.content_type} */}
      </div>
      {DEV_FEATURE && <Id>{data.id}</Id>}
    </Caption>
  </>
);
